import { memoize } from 'lodash';

import { FLOAT_COLUMNS, ROUND_COLUMNS } from 'components/Inventory/table-config';

import { clone, formatDecimal, formatNumber, isArray, isEmpty, isNotEmpty, isObject } from 'util/utils';

export const formatValues = (value, key) => {
  if (isArray(value)) return value;
  if (isObject(value)) return value;

  return isEmpty(value) || value === 0
    ? '-'
    : FLOAT_COLUMNS.includes(key)
      ? formatDecimal(value)
      : ROUND_COLUMNS.includes(key)
        ? formatNumber(Math.round(value))
        : value;
};

/**
 * Update stone record
 * @param {*} record stone record - source will be mutated - pass a deep copy if mutation is causing side-effects
 * @param {*} options options
 * @returns copy of formatted record
 */
export const updateRecord = (record, options = {}) => {
  options = { clone: true, ...options };

  if (!isObject(record)) return;

  if (!record._original) {
    record._original = { ...record };
  }

  const bt2Found =
    isArray(options.inBlockList) &&
    options?.inBlockList.findIndex((blockRecord) => {
      return record?.id === blockRecord?._id?.diamond && blockRecord?._id?.blockType === 2;
    }) > -1;

  record.colNm = record?.isFcCol ? record?.fcColDesc : record?.colNm;
  record.lbNm = record?.sSts === 'C' || record?.sStsDesc === 'GIA Result' ? record?.sStsDesc : record?.lbNm;
  record.sgiMemo = !options?.inOrder && record?.webSts !== 'I' && record?.wSts === 'M' && !bt2Found;
  record.sgiHold = !options?.inOrder && record?.sSts === 'Y' && record?.wSts === 'H';
  record.bestBuyMemo = record?.wSts === 'D' && record?.sgiSts === 'I';
  record.bestBuyHold = record?.initSts === 'D';

  return options.clone ? clone(record) : record;
};

/**
 * Update stone record
 * @param {*} list list of stone records - source will be mutated - pass a deep copy if mutation is causing side-effects
 * @param {*} options options
 * @returns copy of formatted record
 */
export const updateList = (list, options = {}) => {
  if (!isArray(list)) return [];

  const updatedList = list.map(record => {
    const recordCopy = { ...record };
    updateRecord(recordCopy, { clone: false, ...options });
    return recordCopy;
  });

  return updatedList;
};

export const filterRecord = (record, remove = []) => {
  if (!isObject(record)) return;
  record = { ...record, ...record?._original };
  const byUpdater = []; // 'sgiMemo', 'sgiHold', 'bestBuyMemo'
  const byGroupping = ['isFooter', 'isHeader', 'groupData', 'totalDiamonds']; // '_groupBy_'
  const byGroupType = ['isHoldHeader', 'isMatchHeader', 'userGroupHold'];
  const keysToDelete = [...byUpdater, ...byGroupping, ...byGroupType, ...remove];
  keysToDelete.forEach((key) => delete record[key]);
  return record;
};

export const filterList = (list, remove = []) => {
  if (!isArray(list)) return;
  return list.map((record) => filterRecord({ ...record }, remove));
};

export const getStickyStyle = memoize(({ noStatus = false }) => {
  const stickyHeader = { position: 'sticky', zIndex: 2000 };
  const stickyCell = { position: 'sticky', backgroundColor: '#fff', zIndex: 1000 };
  const firstWidth = noStatus ? 40 : 140;
  const secondWidth = 160;
  const firstLeft = -10;
  const secondLeft = firstWidth + firstLeft - 5;
  const thirdLeft = firstWidth + secondWidth + firstLeft - 10;

  return {
    head: {
      first: { top: '-10px', left: firstLeft + 'px', ...stickyHeader, minWidth: firstWidth + 'px' },
      second: { top: '-10px', left: secondLeft + 'px', ...stickyHeader, minWidth: secondWidth + 'px' },
      third: { top: '-10px', left: thirdLeft + 'px', ...stickyHeader },
    },
    cell: {
      first: { left: firstLeft + 'px', ...stickyCell, minWidth: firstWidth + 'px' },
      second: { left: secondLeft + 'px', ...stickyCell, minWidth: secondWidth + 'px' },
      third: { left: thirdLeft + 'px', ...stickyCell },
    },
  };
});

export const getGroupTitle = (record = {}, options = {}) => {
  const dateTitle = record?.dateTitle ?? '';
  const country = record?.countryNm ?? '-';
  const salesPerson = record?.seller ?? '-';
  const date = record?.userDate ?? record?.createdAt ?? '-';

  const user = [
    record?.user?.account?.companyName ?? record?.user?.companyName,
    record?.userName,
    record?.userMobile,
    record?.userEmail,
  ]
    .filter(isNotEmpty)
    .join(' | ');

  const bidGroup = [record?.vStnId ?? '-', record?.totalDiamonds, record?.userName].filter(isNotEmpty).join(' | ');

  return options?.user && options?.date
    ? `${dateTitle} ${date} | ${user}`
    : options?.bidGrouping
      ? bidGroup
      : options?.sortStone
        ? record?.vStnId ?? '-'
        : options?.user
          ? user
          : options?.date
            ? `${dateTitle} ${date}`
            : options?.country
              ? country
              : options?.salesPerson
                ? salesPerson
                : options?.stageShow
                  ? `${record?.blockCode ?? '-'}`
                  : options?.orderShow
                    ? `${record?.memoNo ?? '-'}`
                    : `${dateTitle} ${date} | ${user}`;
};

export const memoizedGetGroupTitle = memoize(getGroupTitle, (...args) => JSON.stringify(args));

export const filterGridColumns = (columns = [], options = {}) => {
  columns =
    options?.dateList && options?.customerList
      ? columns.filter((el) => el.id !== 'createdAt' && el.id !== 'user')
      : options?.dateList
        ? columns.filter((el) => el.id !== 'createdAt')
        : options?.customerList
          ? columns.filter((el) => el.id !== 'user')
          : columns;

  if (options?.inClient) columns = columns.filter((el) => el.id !== 'user');

  return columns;
};

export const updateRowClassList = (accessor, rowId, select = false) => {
  const tableRows = document.querySelectorAll(accessor ? `[table-row="${accessor}${rowId}"]` : `tr[table-row]`);
  if (!isEmpty(tableRows)) for (const row of tableRows) row.classList[select ? 'add' : 'remove']('selectTr');
};
